/** @format */

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Address from "./address";
import Payment from "./payment";
import Items from "./items";

export default function AddOrder({ openItem, handleItemClose, addAlert }) {
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([{ id: "", qty: 1 }]);
  const [subItems, setSubItems] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({
    total: { value: 0 },
    type: { value: "promo" },
    orderId: { value: "" },
  });
  const [shippingAddress, SetShippingAddress] = useState({});
  const [expanded, setExpanded] = useState("panel1");
  const validated = true; // The code I deleted boils down to this, should be fixed

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    let url = `/api/items?q=nsg=false&limit=200`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((resp) => {
        const bulkPack = "63b8509044cc40310682b42e";
        let newSubItems = { "63b8509044cc40310682b42e": [] };
        let newItems = resp.filter((item) => {
          if (item.parent) {
            if (!newSubItems[item.parent]) {
              newSubItems[item.parent] = [];
            }
            newSubItems[item.parent].push(item);
            if (
              item.tags.material === "PLA" ||
              item.tags.material === "PLA Ltd"
            ) {
              newSubItems[bulkPack].push(item);
            }
          }
          return !item.parent;
        });

        setProducts(newItems);
        setSubItems(newSubItems);
      })
      .catch((e) => {
        addAlert(e.message, "error");
      });
    // eslint-disable-next-line
  }, []);

  const submit = () => {
    let url = `/api/sales/`;
    let shippingInfo = {};
    Object.keys(shippingAddress).forEach((k) => {
      if (k === "serviceCode") {
        shippingInfo[k] = shippingAddress[k].code;
      } else {
        shippingInfo[k] = shippingAddress[k].value;
      }
    });

    let payments = {};
    Object.keys(paymentInfo).forEach((k) => {
      if (paymentInfo[k].code) {
        payments[k] = paymentInfo[k].code;
      } else {
        payments[k] = paymentInfo[k].value;
      }
    });

    const body = {
      items,
      shippingAddress: shippingInfo,
      paymentInfo: payments,
    };

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((resp) => {
        handleItemClose();
      })
      .catch((e) => {
        addAlert(e.message, "error");
      });
  };

  const onClose = () => {
    handleItemClose();
  };

  return (
    <Dialog
      open={openItem}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Create Order</DialogTitle>
      <DialogContent>
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography>Items</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Items
              products={products}
              subItems={subItems}
              onChange={setItems}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography>Shipping</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Address updateFunc={SetShippingAddress} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography>Payment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Payment updateFunc={setPaymentInfo} />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        {expanded === "panel3" ? (
          <Button
            onClick={submit}
            disabled={validated !== true}
            variant="outlined"
            color="primary"
          >
            Add Order
          </Button>
        ) : (
          <Button
            onClick={() => {
              let index = Number(expanded.slice(-1));
              setExpanded("panel" + (index + 1));
            }}
            variant="outlined"
            color="primary"
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
