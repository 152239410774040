/** @format */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/MailOutline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import SimpleDialog from "../../dialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Boxes from "../shipping/addbox";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Input from "@mui/material/Input";
import NativeSelect from "@mui/material/NativeSelect";
import { STATUSES } from "../../constants";
import Carousel from "../../components/carousel";
import Confirm from "../../dialog/confirm";
import Growl from "../../components/growl";
import {
  pickListXml,
  boxLabelXml,
  orderLabelXml,
  orderWith285Xml,
} from "../../components/labels";
import ListItemsIcon from "@mui/icons-material/Assignment";
import { SHIPPINGCODES } from "../../constants";
import BusinessIcon from "@mui/icons-material/Business";
import AdjustIcon from "@mui/icons-material/Adjust";
import StarIcon from "@mui/icons-material/Star";
import MoneyOff from "@mui/icons-material/MoneyOff";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const dymo = window.dymo;

let closeImg;

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  preview: {
    maxHeight: 75,
  },
  label: {
    position: "absolute",
    bottom: 20,
    right: 20,
    zIndex: 1000,
    opacity: 0,
    visibility: "hidden",
  },
  textField: {
    marginTop: 0,
  },
  summary: {
    "&::before": {
      border: "none",
      backgroundColor: "white",
    },
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
  shippingButton: {
    marginRight: theme.spacing(1),
    height: 35,
    "& span span i": {
      fontSize: "1rem !important",
    },
  },
}));

export default function Orders(props) {
  const { match, printerName, zebraPrinter, addAlert } = props;
  const id = match.params.id;
  const history = useHistory();
  const [order, setOrder] = useState({});
  const [boxes, setBoxes] = useState([]);
  const [sendBoxes, setSendBoxes] = useState(false);
  const [fetching, setFetching] = useState(true);
  const classes = useStyles();
  const [d, setD] = useState({ content: "", title: "", show: false });
  const [c, setC] = useState({ content: "", title: "", show: false });
  const [growl, setGrowl] = useState({ type: "success", msg: "", show: false });
  const [shipping, setShipping] = useState(false);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [saturday, setSaturday] = useState(false);
  const [disableShip, setDisableShip] = useState(false);
  const [firstOrder, setFirstOrder] = useState(false);
  const [itemDetails, setItemDetails] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [updateShipping, setUpdateShipping] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [vend, setVend] = useState();
  const [d285, setD285] = useState(0);
  const [checked, setChecked] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  useEffect(() => {
    getOrder();
    // getNotes();
    setChecked({});
    // eslint-disable-next-line
  }, [id]);

  const toggleHistory = () => {
    setShowHistory((prev) => !prev);
  };

  const getOrder = () => {
    let url = "/api/sales/" + id;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        // open dialog
        let hasMystery = false;
        let uspsTracking;
        if (resp.trackingInfo) {
          resp.trackingInfo.forEach((t) => {
            if (t.additionalInfo) {
              uspsTracking = t.additionalInfo
                .filter((u) => {
                  return (u.uspsApplicationId =
                    "USPS" && u.trackingNumber.length > 12);
                })
                .map((u) => u.trackingNumber)
                .join(",");
            }
          });
          resp.uspsTracking = uspsTracking;
        }
        let sides = new Set();
        resp.lines.forEach((line) => {
          if (line.sides && line.sides.length > 0) {
            line.sides.forEach((item) => sides.add(item));
          }
          if (line.itemId === "61f05643cfc23be4c2533af1") {
            hasMystery = true;
          }
        });

        getItems(sides);

        setOrder(resp);

        if (resp.sub === true && hasMystery === true) {
          getVend();
        }

        if (!resp.shipping.hasOwnProperty("serviceCode")) {
          resp.shipping.serviceCode = 1;
        }
        setShippingData(resp.shipping);
        getOrderHistory(resp.customer.email);

        let tmp = 0;
        resp.lines.forEach((line) => {
          if (line.options.diameter === "2.85mm") {
            tmp += line.qty;
          }
        });
        setD285(tmp);
        setFetching(false);
      })
      .catch((e) => {});
  };

  const getItems = (itemIds) => {
    let url = `/api/items?q=nsg=false&limit=150`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((resp) => {
        let items = resp.filter((item) => itemIds.has(item.id));
        let tmp = {};
        items.forEach((item) => {
          tmp[item.id] = item;
        });
        setItemDetails(tmp);
      });
  };

  const getOrderHistory = (email) => {
    let url = "/api/sales?getEmail=" + email;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        // open dialog
        setFirstOrder(resp.count === 1);
      })
      .catch((e) => {});
  };

  const cancelItem = (id, qty) => {
    setC({
      content:
        "Are you sure you want to refund and cancel line " + id.slice(-4),
      title: "Cancel",
      show: true,
      handle: (ok) => {
        if (ok === true) {
          refundLine(id, qty);
        }
        setC({ content: "", title: "", show: false, handle: () => {} });
      },
    });
  };

  const cancelOrder = () => {
    setC({
      content: "Are you sure you want to refund and cancel this order?",
      title: "Cancel",
      show: true,
      handle: (ok) => {
        if (ok === true) {
          refundOrder();
        }
        setC({ content: "", title: "", show: false, handle: () => {} });
      },
    });
  };

  const openImage = (images) => {
    let content = (
      <div style={{ padding: 20 }}>
        <Carousel images={images} />
      </div>
    );
    setD({ content, title: "Images", show: true });
  };

  const changeLineStatus = (id) => (e) => {
    let { value, name } = e.target;
    if (!isNaN(Number(value))) {
      value = Number(value);
    }
    let update = {};
    update[name] = value;
    updateLine(id, update);
    let tmp = Object.assign({}, order);
    let statuses = new Set();
    tmp.lines.forEach((line) => {
      if (line.id === id) {
        line[name] = value;
      }
      statuses.add(line.status);
    });
    if (statuses.size === 1) {
      tmp.status = Array.from(statuses)[0];
    }
    setOrder(tmp);
  };

  const editItem = (line) => {
    // setC({
    //   content: 'Are you sure you want to reprint line ' + line.id,
    //   title: 'Reprint',
    //   show: true,
    //   handle: ok => reprintHandle(ok, line),
    // })
  };

  const printPickLabel = (items) => (e) => {
    e.preventDefault();

    if (!printerName) {
      addAlert("No printers found", "error");
      return;
    }

    let filterItems = items;

    let checkedItems = Object.keys(checked).filter((key) => {
      return checked[key] === true;
    });

    if (checkedItems.length > 0) {
      filterItems = items.filter((item) => {
        return checked[item.id] === true;
      });
    }

    //print overall order label
    let orderPrintParamsXml =
      dymo.label.framework.createLabelWriterPrintParamsXml({ copies: 1 });
    let orderLabel = dymo.label.framework.openLabelXml(orderLabelXml(order));
    orderLabel.print(printerName, orderPrintParamsXml);

    //print line items on ~n labels, 7 per label
    let pickListParamsXml =
      dymo.label.framework.createLabelWriterPrintParamsXml({ copies: 1 });
    let splitLines = array_chunks(filterItems, 7);
    splitLines.forEach((split) => {
      let pickListLabel = dymo.label.framework.openLabelXml(
        pickListXml(split, itemDetails)
      );
      pickListLabel.print(printerName, pickListParamsXml);
    });
  };

  const printBoxLabel = (items) => (e) => {
    e.preventDefault();
    if (!printerName) {
      addAlert("No printers found", "error");
      return;
    }
    let closeImg;
    let imgElem = document.getElementById("labelimg");
    //print box labels
    if (d285 > 0) {
      let printParamsXml = dymo.label.framework.createLabelWriterPrintParamsXml(
        { copies: 1 }
      );
      let labelXml = orderWith285Xml(d285);
      let label = dymo.label.framework.openLabelXml(labelXml);
      label.print(printerName, printParamsXml);
      clearTimeout(closeImg);
      closeImg = setTimeout(fadeOut(imgElem), 5000);
    }

    items.forEach((item, index) => {
      if (
        Object.keys(checked).filter((id) => checked[id] !== false).length > 0
      ) {
        if (!checked[item.id]) {
          return;
        }
      }
      if (item.name === "Starter Kit") {
        let tShirtObj = JSON.parse(JSON.stringify(item));
        delete tShirtObj.data.temp;
        tShirtObj.options.material = "T-Shirt";
        tShirtObj.options.color = "Black";
        tShirtObj.options.colorName = "Black";
        printDymoLabel(tShirtObj);

        let miniObj = JSON.parse(JSON.stringify(item));
        miniObj.options.material = "MINI";
        miniObj.options.color = "Red, White, Blue, Yellow, Black";
        miniObj.data.temp = 200;
        miniObj.options.diameter = "1.75mm";
        printDymoLabel(miniObj);

        let forgeObj = JSON.parse(JSON.stringify(item));
        forgeObj.options.material = "FORGE";
        forgeObj.options.color = "Black";
        forgeObj.options.colorName = "Black";
        forgeObj.data.temp = 210;
        forgeObj.options.diameter = "1.75mm";
        printDymoLabel(forgeObj);
      } else if (item.options.weight === "5kg") {
        printDymoLabel(item);
        let partObj = JSON.parse(JSON.stringify(item));
        delete partObj.data.temp;
        partObj.options.material = "parts";
        partObj.options.color = "Bearings";
        partObj.options.colorName = "Bearings";
        partObj.options.diameter = "";
        printDymoLabel(partObj);
      } else {
        printDymoLabel(item);
      }
      if (index === 0) {
        fadeIn(imgElem);
      }
    });
  };

  const printDymoLabel = (item) => {
    let imgElem = document.getElementById("labelimg");
    let printParamsXml = dymo.label.framework.createLabelWriterPrintParamsXml({
      copies: item.qty,
    });
    let labelXml = boxLabelXml(item, itemDetails);
    let label = dymo.label.framework.openLabelXml(labelXml);
    let image = label.render();
    imgElem.src = "data:image/png;base64," + image;
    label.print(printerName, printParamsXml);
    clearTimeout(closeImg);
    closeImg = setTimeout(fadeOut(imgElem), 5000);
  };

  const getShippingLabel = () => {
    let url = `/api/shipping/order/${id}/boxes`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setBoxes(resp);
        setShipping(true);
        // open dialog
      })
      .catch((e) => {
        console.error(e);
        // setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  const addTrackingInfo = () => {
    const number = prompt("Enter Tracking Number");
    if (number) {
      let url = `/api/sales/${id}/`;
      fetch(url, {
        method: "PUT",
        body: JSON.stringify({ trackingInfo: [{ trackingNum: number }] }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then((resp) => {
          getOrder();
          // open dialog
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const updateBoxes = (boxes) => {
    setBoxes(boxes);
    setSendBoxes(true);
  };

  const shipPackage = (e) => {
    setDisableShip(true);
    let url = `/api/shipping/order/${id}?saturday=${saturday}`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(sendBoxes ? boxes : {}),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        if (typeof resp === "string") {
          try {
            resp = JSON.parse(resp);
          } catch (e) {
            console.error(e.message);
          }
        }
        if (resp[0] && resp[0].message) {
          setGrowl({ type: "error", msg: resp[0].message, show: true });
        } else {
          printShippingLabel(resp);
          setBoxes([]);
          setSendBoxes(false);
          setShipping(false);
          getOrder();
          setDisableShip(false);
        }
        // open dialog
      })
      .catch((e) => {
        setDisableShip(false);
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const voidShippingLabel = () => {
    let url = `/api/shipping/order/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setGrowl({ type: "info", msg: "Label has been voided", show: true });
        getOrder();
      })
      .catch((e) => {
        console.error(e);
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const createCallTag = () => {
    if (
      !window.confirm(
        "Are you sure you want to create a call tag for this order?"
      )
    ) {
      return;
    }
    let url = `/api/shipping/order/${id}/calltag`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setGrowl({
          type: "info",
          msg: "A Call tag was created and emailed to the customer.",
          show: true,
        });
        getOrder();
      })
      .catch((e) => {
        console.error(e);
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const fadeIn = (imgElem) => {
    imgElem.style.visibility = "visible";
    imgElem.style.opacity = 1;
    imgElem.style.transition = "opacity 1s linear";
  };

  const fadeOut = (imgElem) => () => {
    imgElem.style.visibility = "hidden";
    imgElem.style.opacity = 0;
    imgElem.style.transition = "visibility 0s 2s, opacity 1s linear";
  };

  const updateLine = (id, data) => {
    let url = "/api/sales/line/" + id;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        let tmp = { ...order, ...resp };
        setOrder(tmp);
        // open dialog
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const getIcon = (payment) => {
    const { refType, refId, brand } = payment;
    let icon;
    switch (refType.toLowerCase()) {
      case "amazon":
      case "amzn":
        icon = (
          <a
            href={refId}
            target="_blank"
            style={{ color: "black" }}
            rel="noopener noreferrer"
          >
            <i className="fab fa-amazon"></i>
          </a>
        );
        break;
      case "paypal":
        icon = (
          // eslint-disable-next-line
          <a
            // eslint-disable-next-line
            href="javascript:void(0);"
            onClick={getReceipt}
            style={{ color: "black" }}
          >
            <i className="fab fa-paypal"></i>
          </a>
        );
        break;
      case "ebay":
        icon = <i className="fab fa-ebay"></i>;
        break;
      case "charge":
        let card =
          brand.toLowerCase() === "american express"
            ? "amex"
            : brand.toLowerCase();
        card = `fab fa-cc-${card}`;
        icon = (
          // eslint-disable-next-line
          <a
            href={void 0}
            onClick={getReceipt}
            style={{ color: "black", cursor: "pointer" }}
          >
            <i className={card}></i>
          </a>
        );
        break;
      case "promo":
        icon = (
          <a href={refId} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-gift"></i>
          </a>
        );
        break;
      default:
        icon = <i className="fa fa-box"></i>;
        break;
    }

    return icon;
  };

  const getTracking = (e) => {
    e.stopPropagation();
    console.log("get tracking");
    let url = "/api/sales/" + id + "/receipt?tracking=true";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        // open dialog
        openPopup(resp.html, "TrackingWindow");
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };
  const openPopup = (data, title) => {
    let myWindow = window.open("", title);
    myWindow.document.write(data);
  };

  const getReceipt = () => {
    let url = "/api/sales/" + id + "/receipt";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        // open dialog
        let myWindow = window.open("", "ReceiptWindow");
        myWindow.document.write(resp.html);
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const rePrintShippingLabel = () => {
    printShippingLabel(order.trackingInfo);
  };

  const inProgress = () => {
    updateOrderStatus("in progress");
  };

  const updateOrderStatus = (status) => {
    let url = "/api/sales/" + id;
    let data = { status };
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        getOrder();
        // open dialog
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const printShippingLabel = (labels) => {
    labels.forEach((t) => {
      if (t.encodedLabel) {
        zebraPrinter.send(
          atob(t.encodedLabel),
          () => {},
          (e) => {
            setGrowl({
              type: "error",
              msg: "There was an issue sending label to the printer.  Is browserprint running?",
              show: true,
            });
          }
        );
      } else {
        zebraPrinter.convertAndSendFile(
          `/api/shipping/zpl?url=${t.label}&type=.zpl`,
          () => {},
          (e) => {
            setGrowl({
              type: "error",
              msg: "There was an issue sending label to the printer.  Is browserprint running?",
              show: true,
            });
          }
        );
      }
    });
  };

  const isFriday = () => {
    let date = new Date();
    if (date.getDay() === 5 && date.getHours() < 18) {
      return true;
    } else if (date.getDay() === 4 && date.getHours() > 17) {
      return true;
    } else {
      return false;
    }
  };

  const handleShippingChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "serviceCode") {
      value = Number(value);
    }
    let tmp = { ...shippingData };
    tmp[name] = value;
    setShippingData(tmp);
    setUpdateShipping(true);
  };

  const toggleBulk = () => {
    let tmp = { ...shippingData };
    tmp["bulk"] = !shippingData.bulk;
    setShippingData(tmp);
    setUpdateShipping(true);
  };

  const getNotes = () => {
    let url = "/api/notes/refId/" + id;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setNotes(resp);
        // open dialog
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const getVend = () => {
    let url = "/api/vend/orderId/" + id;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        if (resp.hasOwnProperty("id")) {
          setVend(resp);
        }
        // open dialog
      })
      .catch((e) => {
        // setGrowl({type: 'error', msg: e.message, show: true});
      });
  };

  const addNote = () => {
    let url = "/api/notes/";
    let data = { note: note, refId: id, refType: "sales" };
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        // getNotes();
        setNote("");
        // open dialog
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const updateShippingData = () => {
    let url = "/api/sales/" + id;
    let data = { shipping: shippingData };
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setShipping(resp.shpping);
        setUpdateShipping(false);
        // open dialog
      })
      .catch((e) => {
        setGrowl({ type: "error", msg: e.message, show: true });
      });
  };

  const copyToClipBoard = (trackingNum) => (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(trackingNum);
    setGrowl({
      type: "info",
      msg: "Tracking Number Copied to Clipboard",
      show: true,
    });
    return false;
  };

  const getDocuments = (docs) => () => {
    let b64 = docs[0].encodedLabel;
    var obj = document.createElement("object");
    obj.style.width = "100%";
    obj.style.height = "842pt";
    obj.type = "application/pdf";
    obj.data = "data:application/pdf;base64," + b64;
    document.body.appendChild(obj);

    var link = document.createElement("a");
    link.innerHTML = "Download PDF file";
    link.download = "file.pdf";
    link.style.display = "none";
    link.href = "data:application/octet-stream;base64," + b64;
    document.body.appendChild(link);
    // link.click();
    clickLink(link);
  };
  const clickLink = (link) => {
    var cancelled = false;

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      cancelled = !link.dispatchEvent(event);
    } else if (link.fireEvent) {
      cancelled = !link.fireEvent("onclick");
    }

    if (!cancelled) {
      window.location = link.href;
    }
  };

  const reorder = () => {
    if (Object.keys(checked).length === 0) {
      setGrowl({
        type: "error",
        msg: "Please select at least on line to reorder.",
        show: true,
      });
      return;
    }
    let note = prompt("Please enter a reason for the reorder.");
    let url = `/api/sales/`;
    let shippingInfo = { ...order.shipping };
    shippingInfo.note = note;

    let payments = {
      orderId: order.id,
      total: 0,
      type: "promo",
    };

    let items = order.lines
      .filter((line) => {
        return checked.hasOwnProperty(line.id);
      })
      .map((line) => {
        return { ...line };
      });

    items.forEach((line) => {
      line.status = "received";
    });

    const body = {
      items,
      shippingAddress: shippingInfo,
      paymentInfo: payments,
      cartLines: true,
    };
    // console.log(body);

    // return;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((resp) => {
        //open dialog
        console.log(resp);
        setGrowl({
          type: "success",
          msg: "New order Created.  Moving to new order.",
          show: true,
        });
        setTimeout(() => {
          history.push("/order/" + resp.id);
        }, 5000);
      })
      .catch((e) => {
        // addAlert(e.message, 'error');
        setGrowl({
          type: "error",
          msg: e.message,
          show: true,
        });
      });
  };
  const refundOrder = (refundShipping = true, returnToInventory = true) => {
    let url = `/api/sales/refund/${id}/?isShippingRefunded=${refundShipping}&shouldReturnInventory=${returnToInventory}`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((resp) => {
        //open dialog
        setGrowl({
          type: "success",
          msg: `Order has been refunded in the amount of ${(
            resp.amount / 100
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}.`,
          show: true,
        });
        getOrder();
      })
      .catch((e) => {
        // addAlert(e.message, 'error');
        setGrowl({
          type: "error",
          msg: e.message,
          show: true,
        });
      });
  };

  const refundLine = (lineId, qty, returnToInventory = false) => {
    let url = `/api/sales/refund/${id}/line/${lineId}?qty=${qty}&shouldReturnInventory=${returnToInventory}`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then(function (res) {
        if (res.status >= 400 && res.status < 600) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((resp) => {
        //open dialog
        setGrowl({
          type: "success",
          msg: `Line has been refunded in the amount of ${(
            resp.amount / 100
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}.`,
          show: true,
        });
        getOrder();
      })
      .catch((e) => {
        // addAlert(e.message, 'error');
        setGrowl({
          type: "error",
          msg: e.message,
          show: true,
        });
      });
  };

  const getSides = (sides) => {
    let sidesObj = {};
    sides.forEach((id) => {
      if (!sidesObj[id]) {
        sidesObj[id] = 0;
      }
      sidesObj[id]++;
    });
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          {Object.keys(sidesObj).map((key, index) => {
            let item = itemDetails[key];
            if (!item) {
              return null;
            }
            const { tags } = item;
            const { color, diameter } = tags;
            return (
              <tr key={index}>
                <td style={{ width: "75%" }}>
                  <Typography variant="body1">
                    {color}{" "}
                    {diameter !== "1.75mm" ? <strong>{diameter}</strong> : ""}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body1">x{sidesObj[key]}</Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const getColors = (colors = "") => {
    let colorArray = colors.split(",");
    let colorObj = {};
    colorArray.forEach((color) => {
      color = color.trim();
      if (!colorObj[color]) {
        colorObj[color] = 0;
      }
      colorObj[color]++;
    });

    if (colorArray.length > 1) {
      return (
        <table style={{ width: "100%" }}>
          <tbody>
            {Object.keys(colorObj).map((key, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "75%" }}>
                    <Typography variant="body1">{key}</Typography>
                  </td>
                  <td>
                    <Typography variant="body1">x{colorObj[key]}</Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return colors;
    }
  };

  return (
    <>
      {fetching === true ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={5} lg={4}>
            <Paper
              className={fixedHeightPaper}
              style={{ backgroundColor: order.sub === true ? "#CBC3E3" : "" }}
            >
              <div style={{ padding: 40, textAlign: "left" }}>
                <h3 style={{ marginTop: 0 }}>Order Details</h3>
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Name"
                  name="name"
                  className={classes.textField}
                  value={
                    order.shipping.name ||
                    order.shipping.firstName + " " + order.shipping.lastName
                  }
                  InputProps={{
                    startAdornment:
                      order.sub === true ? (
                        <Tooltip title="Premium Member">
                          <StarIcon style={{ cursor: "pointer" }} />
                        </Tooltip>
                      ) : null,
                    endAdornment:
                      order.customer.email || order.shipping.email ? (
                        <a
                          style={{ color: "black" }}
                          href={`mailto:${
                            order.customer.email || order.shipping.email
                          }`}
                        >
                          <MailIcon style={{ cursor: "pointer" }} />
                        </a>
                      ) : null,
                  }}
                  fullWidth
                  // onChange={handleChange('name')}
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Date"
                  className={classes.textField}
                  value={new Date(order._system.created).toLocaleString()}
                  fullWidth
                  // onChange={handleChange('name')}
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Status"
                  className={classes.textField}
                  value={order.status.toTitleCase()}
                  fullWidth
                  // onChange={handleChange('name')}
                  margin="normal"
                />
                {order.trackingInfo && (
                  <TextField
                    variant="standard"
                    id="standard-name"
                    label="Tracking #"
                    className={classes.textField}
                    value={order.trackingInfo[0].trackingNum}
                    fullWidth
                    onClick={(e) => {
                      let fedexTrackingNum = order.trackingInfo.map((t) => {
                        return t.trackingNum;
                      });
                      const a = document.createElement("a");
                      a.href = `https://www.fedex.com/fedextrack/?tracknumbers=${fedexTrackingNum}`;
                      a.target = "_blank";
                      document.body.appendChild(a);
                      a.click();
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {/*eslint-disable-next-line*/}
                          <a
                            href="#"
                            style={{ color: "black" }}
                            onClick={copyToClipBoard(
                              order.trackingInfo[0].trackingNum
                            )}
                          >
                            <i className="fas fa-copy"></i>
                          </a>
                          {/*eslint-disable-next-line*/}
                          <a
                            href={`#`}
                            onClick={getTracking}
                            style={{ color: "black", marginLeft: 10 }}
                          >
                            <MailIcon style={{ cursor: "pointer" }} />
                          </a>
                        </>
                      ),
                    }}
                    margin="normal"
                  />
                )}
                {order.trackingInfo && order.trackingInfo[0].status && (
                  <TextField
                    variant="standard"
                    id="standard-name"
                    label="Tracking Detail"
                    className={classes.textField}
                    multiline={true}
                    rows={2}
                    value={`${order.trackingInfo[0].status.statusByLocale}: ${order.trackingInfo[0].status.description}\nLocation: ${order.trackingInfo[0].status.scanLocation.city}, ${order.trackingInfo[0].status.scanLocation.stateOrProvinceCode} `}
                    fullWidth
                    margin="normal"
                  />
                )}
                {order.trackingNum && (
                  <TextField
                    variant="standard"
                    id="standard-name"
                    label="Tracking #"
                    className={classes.textField}
                    value={order.trackingNum}
                    fullWidth
                    onClick={() => {
                      const a = document.createElement("a");
                      a.href = `https://www.fedex.com/fedextrack/?tracknumbers=${order.trackingNum}`;
                      a.target = "_blank";
                      document.body.appendChild(a);
                      a.click();
                    }}
                    InputProps={{
                      endAdornment: (
                        <button>
                          <i className="far fa-copy"></i>
                        </button>
                      ),
                    }}
                    margin="normal"
                  />
                )}
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Total Amount"
                  className={classes.textField}
                  value={(order.payment.amount / 100).toFixed(2)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: getIcon(order.payment),
                  }}
                  fullWidth
                  margin="normal"
                />
                <Accordion
                  elevation={0}
                  className={classes.summary}
                  square={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ paddingLeft: 0 }}
                  >
                    <Typography>Payment Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      variant="standard"
                      id="standard-name"
                      label="Subtotal"
                      className={classes.textField}
                      value={(
                        (order.payment.amount -
                          order.payment.shippingCost -
                          order.payment.tax +
                          (order.payment.discount || 0)) /
                        100
                      ).toFixed(2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                      margin="normal"
                    />
                    <br />
                    <TextField
                      variant="standard"
                      id="standard-name"
                      label="Shipping"
                      className={classes.textField}
                      value={(order.payment.shippingCost / 100).toFixed(2)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      fullWidth
                      margin="normal"
                    />
                    {order.payment.discount && order.payment.discount > 0 ? (
                      <TextField
                        variant="standard"
                        id="standard-name"
                        label="Discount"
                        className={classes.textField}
                        value={
                          (order.payment.discount / 100).toFixed(2) +
                          ` (${order.payment.coupon})`
                        }
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        margin="normal"
                      />
                    ) : null}
                    {order.payment.tax > 0 && (
                      <TextField
                        variant="standard"
                        id="standard-name"
                        label="Tax"
                        className={classes.textField}
                        value={(order.payment.tax / 100).toFixed(2)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        fullWidth
                        margin="normal"
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                <br />
                {order.shipping.note && (
                  <TextField
                    variant="standard"
                    id="standard-name"
                    label="Notes"
                    multiline
                    rows={4}
                    className={classes.textField}
                    value={order.shipping.note}
                    fullWidth
                    margin="normal"
                  />
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={false} md={false} lg={4}>
            <Paper className={fixedHeightPaper}>
              <div style={{ padding: 45, textAlign: "left", paddingTop: 20 }}>
                <h3>Notes</h3>
                {notes.map((n) => {
                  return (
                    <p>
                      {n.note} <br />
                      <em style={{ fontSize: ".8rem" }}>
                        {n._system.createdBy.split("_")[0]} -{" "}
                        {new Date(n._system.created).toLocaleDateString()}
                      </em>
                    </p>
                  );
                })}
                <TextField
                  variant="standard"
                  id="standard-note"
                  label="Add Note"
                  name="note"
                  multiline
                  // rows={1}
                  className={classes.textField}
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      addNote();
                    }
                  }}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Paper>
            {order.signsData && (
              <Paper className={fixedHeightPaper} style={{ marginTop: 10 }}>
                <div style={{ padding: 45, textAlign: "left", paddingTop: 20 }}>
                  <h3>Signs 365 Info</h3>
                  <p>Order Number: #{order.signsData?.orderNumber}</p>
                  <p>Status: {order.signsData?.status}</p>
                </div>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper
              className={fixedHeightPaper}
              style={{
                backgroundColor: firstOrder === true ? "lightgreen" : "white",
              }}
            >
              <div style={{ padding: 45, textAlign: "left", paddingTop: 20 }}>
                <h3>
                  Shipping Details{firstOrder === true ? " - First Order" : ""}
                </h3>{" "}
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Name"
                  name="name"
                  className={classes.textField}
                  onChange={handleShippingChange}
                  value={
                    shippingData.name ||
                    shippingData.firstName + " " + shippingData.lastName
                  }
                  InputProps={{
                    endAdornment:
                      shippingData.bulk === true ? (
                        <IconButton onClick={toggleBulk} size="large">
                          <BusinessIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={toggleBulk} size="large">
                          <AdjustIcon />
                        </IconButton>
                      ),
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Phone"
                  className={classes.textField}
                  value={shippingData.phone}
                  fullWidth
                  type="phone"
                  onChange={handleShippingChange}
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Address"
                  name="address"
                  onChange={handleShippingChange}
                  className={classes.textField}
                  value={shippingData.address}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Address 2"
                  name="address2"
                  onChange={handleShippingChange}
                  className={classes.textField}
                  value={shippingData.address2}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="City"
                  name="city"
                  className={classes.textField}
                  value={shippingData.city}
                  onChange={handleShippingChange}
                  fullWidth
                  margin="normal"
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      id="standard-name"
                      label="State"
                      name="state"
                      className={classes.textField}
                      value={shippingData.state}
                      onChange={handleShippingChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      id="standard-name"
                      label="Zip"
                      name="zipCode"
                      className={classes.textField}
                      value={shippingData.zipCode}
                      onChange={handleShippingChange}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <NativeSelect
                  value={shippingData.serviceCode}
                  onChange={handleShippingChange}
                  input={<Input name="serviceCode" id="serviceCode-helper" />}
                >
                  {SHIPPINGCODES.map((m, i) => {
                    return (
                      <option key={i} value={m.id}>
                        {m.name.toTitleCase()}
                      </option>
                    );
                  })}
                </NativeSelect>
                {updateShipping === true && (
                  <>
                    <br />
                    <br />
                    <Button
                      color="primary"
                      title="Print Pick List"
                      variant="outlined"
                      className={classes.shippingButton}
                      onClick={updateShippingData}
                    >
                      Save
                    </Button>
                    <Button
                      title="Print Pick List"
                      variant="outlined"
                      className={classes.shippingButton}
                      onClick={() => {
                        setShippingData(order.shipping);
                        setUpdateShipping(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{
                display: "flex",
                padding: "10px",
                justifyContent: "flex-start",
              }}
            >
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {order.status === "received" && (
                  <Button
                    color="primary"
                    title="Start Picking"
                    variant="outlined"
                    className={classes.shippingButton}
                    onClick={inProgress}
                    startIcon={<i className="fas fa-warehouse"></i>}
                  >
                    Start Order
                  </Button>
                )}
                {printerName && (
                  <>
                    <Button
                      color="primary"
                      title="Print Pick List"
                      variant="outlined"
                      className={classes.shippingButton}
                      onClick={printPickLabel(order.lines)}
                      startIcon={<ListItemsIcon />}
                    >
                      Pick List
                    </Button>
                    <Button
                      color="primary"
                      title="Print Box Labels"
                      variant="contained"
                      className={classes.shippingButton}
                      onClick={printBoxLabel(order.lines)}
                      startIcon={
                        <i
                          className="fas fa-box-open"
                          style={{ fontSize: "1 rem" }}
                        ></i>
                      }
                    >
                      Box Labels
                    </Button>
                    <img
                      src=""
                      alt=""
                      id="labelimg"
                      className={classes.label}
                    />
                  </>
                )}
                {!order.trackingInfo && (
                  <Button
                    title="Add Tracking Info"
                    variant="outlined"
                    className={classes.shippingButton}
                    onClick={addTrackingInfo}
                    style={{ color: "grey" }}
                    startIcon={<i className="fas fa-plus fa-2x"></i>}
                  >
                    Tracking #
                  </Button>
                )}
                <Button
                  title="Customer Service"
                  variant="outlined"
                  className={classes.shippingButton}
                  onClick={() => {
                    updateOrderStatus("customer service");
                  }}
                  startIcon={<i className="fas fa-phone-square"></i>}
                >
                  CS
                </Button>
                <Button
                  title="Re-order"
                  variant="outlined"
                  className={classes.shippingButton}
                  onClick={reorder}
                  startIcon={<i className="fas fa-redo"></i>}
                >
                  Re-Order
                </Button>
                <Button
                  title="History"
                  variant="outlined"
                  className={classes.shippingButton}
                  onClick={toggleHistory}
                  startIcon={<i className="fa-solid fa-clock-rotate-left"></i>}
                >
                  History
                </Button>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {shippingData.serviceCode === 0 &&
                  (order.status === "received" ||
                    order.status === "in progress") && (
                    <Button
                      color="primary"
                      title="Print Shipping Label"
                      variant="contained"
                      className={classes.shippingButton}
                      onClick={() => {
                        updateOrderStatus("ready for pickup");
                      }}
                      style={{
                        color: "white",
                        backgroundColor: "green",
                        alignSelf: "flex-end",
                      }}
                      startIcon={<i className="fas fa-shopping-bag fa-2x"></i>}
                    >
                      Ready for Pickup
                    </Button>
                  )}
                {shippingData.serviceCode === 0 &&
                  order.status === "ready for pickup" && (
                    <Button
                      color="primary"
                      title="Print Shipping Label"
                      variant="contained"
                      className={classes.shippingButton}
                      onClick={() => {
                        updateOrderStatus("delivered");
                      }}
                      style={{
                        color: "white",
                        backgroundColor: "liteblue",
                        alignSelf: "flex-end",
                      }}
                      startIcon={<i className="fas fa-check fa-2x"></i>}
                    >
                      Delivered
                    </Button>
                  )}
                {zebraPrinter &&
                  !order.trackingInfo &&
                  shippingData.serviceCode !== 0 && (
                    <>
                      {isFriday() && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={saturday}
                              onChange={(e) => {
                                setSaturday(e.target.checked);
                              }}
                            />
                          }
                          label="Saturday Delivery"
                        />
                      )}
                      <Button
                        color="primary"
                        title="Print Shipping Label"
                        variant="contained"
                        className={classes.shippingButton}
                        onClick={getShippingLabel}
                        style={{
                          color: "white",
                          backgroundColor: "green",
                          alignSelf: "flex-end",
                        }}
                        startIcon={<i className="fab fa-fedex fa-2x"></i>}
                      >
                        Ship
                      </Button>
                    </>
                  )}
                {zebraPrinter && order.trackingInfo && (
                  <Button
                    color="primary"
                    title="Re-Print Shipping Label"
                    variant="outlined"
                    className={classes.shippingButton}
                    onClick={rePrintShippingLabel}
                  >
                    <>
                      <i
                        className="fas fa-redo fa-1x"
                        style={{ marginRight: 5 }}
                      ></i>
                      <i className="fab fa-fedex fa-2x"></i>
                    </>
                  </Button>
                )}
                {order.trackingInfo && (
                  <>
                    <Button
                      color="primary"
                      title="Void Shipping Label"
                      style={{ backgroundColor: "red", color: "white" }}
                      variant="contained"
                      className={classes.shippingButton}
                      onClick={voidShippingLabel}
                    >
                      <>
                        <i
                          className="fas fa-times-circle fa-1x"
                          style={{ marginRight: 5 }}
                        ></i>
                        <i className="fab fa-fedex fa-2x"></i>
                      </>
                    </Button>
                    <Button
                      color="primary"
                      title="Create Call Tag for Returns"
                      style={{ backgroundColor: "yellow", color: "black" }}
                      variant="contained"
                      className={classes.shippingButton}
                      onClick={createCallTag}
                    >
                      <>
                        <i
                          className="fas fa-sync fa-1x"
                          style={{ marginRight: 5 }}
                        ></i>
                        <i className="fab fa-fedex fa-2x"></i>
                      </>
                    </Button>
                    {order.trackingInfo[0].documents && (
                      <Button
                        color="primary"
                        title="Download Documents"
                        variant="contained"
                        className={classes.shippingButton}
                        onClick={getDocuments(order.trackingInfo[0].documents)}
                      >
                        <>
                          <i
                            className="fas fa-file-pdf fa-1x"
                            style={{ marginRight: 5 }}
                          ></i>
                          <i className="fab fa-fedex fa-2x"></i>
                        </>
                      </Button>
                    )}
                    <br />
                  </>
                )}
                {(order.payment.refType === "charge" ||
                  order.payment.refType === "paypal") && (
                  <Button
                    color="primary"
                    title="Refund and Cancel Order"
                    variant="contained"
                    className={classes.shippingButton}
                    onClick={cancelOrder}
                    style={{
                      color: "white",
                      backgroundColor: "orange",
                      alignSelf: "flex-end",
                    }}
                    startIcon={<MoneyOff />}
                  >
                    Refund
                  </Button>
                )}
              </Grid>
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={
                          Object.keys(checked).filter(
                            (id) => checked[id] !== false
                          ).length === order.lines.length
                        }
                        onChange={(e) => {
                          let tmp = { ...checked };
                          order.lines.forEach((row) => {
                            tmp[row.id] = e.target.checked;
                          });
                          setChecked(tmp);
                        }}
                        name="Slect All"
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>Line#</TableCell>
                    <TableCell>Items</TableCell>
                    <TableCell>Diameter</TableCell>
                    <TableCell>Color</TableCell>
                    <TableCell>Material</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Sale Amount</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.lines &&
                    order.lines.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <Checkbox
                            checked={checked[row.id] === true}
                            onChange={(e) => {
                              let tmp = { ...checked };
                              tmp[row.id] = e.target.checked;
                              setChecked(tmp);
                            }}
                            name={row.id}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>{row.id.substr(-4)}</TableCell>
                        <TableCell>
                          {row.options.fileId ? (
                            <ButtonBase
                              key={row.id}
                              className={classes.image}
                              style={{
                                width: 50,
                              }}
                              onClick={() => {
                                openImage([
                                  `/api/file/${row.options.fileId}/streams`,
                                ]);
                              }}
                            >
                              <img
                                src={`/api/file/${row.options.fileId}/preview`}
                                alt="preview"
                                className={classes.preview}
                              />
                            </ButtonBase>
                          ) : (
                            <ButtonBase
                              key={row.id}
                              className={classes.image}
                              style={{
                                width: 50,
                              }}
                              onClick={() => {
                                openImage(row.images);
                              }}
                            >
                              <img
                                src={row.images}
                                alt="preview"
                                className={classes.preview}
                              />
                            </ButtonBase>
                          )}
                        </TableCell>
                        <TableCell>
                          <strong>
                            {row.options?.diameter?.toTitleCase()}
                          </strong>
                        </TableCell>
                        <TableCell>
                          {row.options?.material === "Bulk"
                            ? getSides(row.sides)
                            : getColors(row.options.color)}
                        </TableCell>
                        <TableCell>
                          {row.options?.material?.toUpperCase()}
                        </TableCell>
                        <TableCell>{row.qty}</TableCell>
                        <TableCell>
                          <FormControl
                            variant="standard"
                            className={classes.formControl}
                          >
                            <NativeSelect
                              value={row.status}
                              onChange={changeLineStatus(row.id)}
                              input={<Input name="status" id="status-helper" />}
                            >
                              {STATUSES.map((m, i) => {
                                return (
                                  <option key={i} value={m}>
                                    {m.toTitleCase()}
                                  </option>
                                );
                              })}
                            </NativeSelect>
                          </FormControl>
                        </TableCell>
                        <TableCell>${row.price * row.qty}</TableCell>
                        <TableCell align="right">
                          <ButtonGroup
                            color="primary"
                            size="small"
                            aria-label="large outlined secondary button group"
                          >
                            <Button
                              color="primary"
                              title="reprint"
                              className={classes.button}
                              aria-label="download gcode"
                              onClick={() => {
                                editItem(row);
                              }}
                            >
                              <Icon>create</Icon>
                            </Button>
                            <Button
                              color="primary"
                              title="Cancel Line"
                              className={classes.button}
                              aria-label="Cancel Line"
                              onClick={() => {
                                cancelItem(row.id, row.qty);
                              }}
                            >
                              <MoneyOff />
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
            {vend && (
              <Grid item xs={12} style={{ marginTop: "2rem" }}>
                <Paper className={classes.paper} style={{ padding: "1rem" }}>
                  Chosen Mystery Rolls
                  {vend.choices.map((choice) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "2rem",
                          padding: "1rem",
                        }}
                      >
                        {choice.channel}{" "}
                        <img
                          alt=""
                          width={600}
                          src={"/api/file/" + choice.fileId + "/streams"}
                          style={{ marginLeft: "1rem" }}
                        />
                      </div>
                    );
                  })}
                </Paper>
              </Grid>
            )}
            <SimpleDialog
              content={d.content}
              maxWidth={"lg"}
              title={d.title}
              open={d.show}
              onClose={() => {
                setD({ content: "", title: "", show: false });
              }}
            />
            <Dialog
              open={showHistory}
              onClose={toggleHistory}
              maxWidth={"md"}
              fullWidth={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Order History</DialogTitle>
              <DialogContent>
                {!order.history || order.history.lenght === 0 ? (
                  <div>No order history found for this order. </div>
                ) : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Change</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">User</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.history &&
                        order.history.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {row.trackingInfo ? `Tracking Number added.` : ""}
                              {row.shipping ? (
                                <pre>
                                  {JSON.stringify(
                                    objectDiff(
                                      row.shipping.to,
                                      row.shipping.from
                                    ),
                                    null,
                                    4
                                  )}
                                </pre>
                              ) : null}
                              {row.status &&
                                `Status changed to ${
                                  row.status.to || row.status
                                }`}
                            </TableCell>
                            <TableCell>
                              {new Date(row.ts).toLocaleString()}
                            </TableCell>
                            <TableCell align="right">
                              {row.userId || ""}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </DialogContent>
              <DialogActions style={{ justifyContent: "flex-end" }}>
                <Button
                  onClick={toggleHistory}
                  // style={{position: 'absolute', right: 10}}
                  color="primary"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={shipping}
              onClose={() => {
                setShipping(false);
              }}
              maxWidth={"md"}
              fullWidth={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Package Shipment
              </DialogTitle>
              <DialogContent>
                <Boxes rows={boxes} onChange={updateBoxes} />
              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    setBoxes([]);
                    setShipping(false);
                  }}
                  style={{ position: "absolute", right: 10 }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={shipPackage}
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    width: 200,
                  }}
                  variant="contained"
                  disabled={disableShip}
                  autoFocus
                >
                  Ship
                </Button>
              </DialogActions>
            </Dialog>
            <Confirm
              content={c.content}
              maxWidth={"lg"}
              title={c.title}
              open={c.show}
              onClose={c.handle}
            />
            {growl.show === true && (
              <Growl
                type={growl.type}
                message={growl.msg}
                closeHandler={() =>
                  setGrowl({ type: "succcess", msg: "", show: false })
                }
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

const array_chunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));

const objectDiff = (o1, o2) => {
  return Object.fromEntries(Object.entries(o2).filter(([k, v]) => o1[k] !== v));
};
