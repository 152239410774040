/** @format */

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Title from "../title";

export default function Chart({
  dailyTotals,
  title = "Today",
  unit = "Sales (qty)",
  currency = false,
}) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function formatCurrency(tickItem) {
    if (currency === true) {
      return formatter.format(tickItem).slice(0, -3);
    } else {
      return tickItem;
    }
  }

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <ResponsiveContainer>
        <LineChart
          data={dailyTotals}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <Tooltip formatter={formatCurrency} />
          <XAxis dataKey="day" />
          <YAxis tickFormatter={formatCurrency}>
            <Label angle={270} position="left" style={{ textAnchor: "middle" }}>
              {unit}
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="total" stroke="#556CD6" dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
