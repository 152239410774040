/** @format */

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { ConfirmProvider } from "material-ui-confirm";
import muiTheme from "./theme/overrides";
import "./App.css";
import sockets from "./utils/ws";
import Login from "./components/login";
import Cookies from "universal-cookie";
import Home from "./pages/dashboard";
import io from "socket.io-client";
// import Label from './pages/label';
import qs from "qs";
// import JWT from './pages/jwt';

const dymo = window.dymo;
const BrowserPrint = window.BrowserPrint;

const cookies = new Cookies();
let userData = localStorage.getItem("user") ||
  cookies.get("USER") || { userId: "Guest", loggedIn: false };
if (userData && typeof userData === "string") {
  userData = JSON.parse(userData);
  cookies.set("JWT", userData.jwt, { path: "/" });
}

const wsUrl = window.location.origin
  .replace("https", "wss")
  .replace("http", "ws");

const opts = {
  reconnectionDelayMax: 10000,
  path: "/api/ws/sockets/",
  reconnection: false,
  transports:
    process.env.NODE_ENV !== "production"
      ? ["polling"]
      : ["polling", "websocket"], //cannot proxy more that one ws using the hpm so resort to polling in dev.
};

const socket = io(wsUrl, opts);

socket.on("connect", () => {
  // console.log('sales connected');
});

socket.on("connect_error", (err) => {
  // console.log('sales err', err);
});

socket.on("disconnect", (err) => {
  // console.log('sales dis', err);
});

window.onbeforeunload = function () {
  socket.close();
};

function App() {
  const { allowAdmin } = userData;
  const user = userData;
  const [printerName, setPrinterName] = useState(undefined);
  const [zebraPrinter, setZebraPrinter] = useState(undefined);
  const [wsClient, setWsClient] = useState(undefined);

  useEffect(() => {
    if (dymo) {
      try {
        dymo.label.framework.init((env) => {
          if (env.isWebServicePresent === true) {
            var printers = dymo.label.framework.getPrinters();
            for (let printer of printers) {
              if (
                printer.printerType === "LabelWriterPrinter" &&
                printer.isConnected === true &&
                printer.isLocal === true
              ) {
                setPrinterName(printer.name);
                break;
              }
            }
          }
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (BrowserPrint) {
      try {
        BrowserPrint.getDefaultDevice("printer", (device) => {
          setZebraPrinter(device);
        });
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userData.userId && userData.loggedIn === true) {
      const url =
        window.location.protocol +
        "//" +
        window.location.host.replace(":3000", ":10112");
      setWsClient(sockets.init(url, userData.jwt));
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Router>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <ConfirmProvider>
            {allowAdmin !== true ? (
              <div className="App">
                {/* <Route exact path={'/jwt/:jwt'} component={JWT} /> */}
                <Route exact path={["/", "/login"]} component={Login} />
              </div>
            ) : (
              <div className="App">
                <Route
                  path="/label"
                  render={(props) => {
                    props.location.qs = qs.parse(
                      props.location.search.slice(1)
                    );
                    // return <Label userId={user.userId} {...props} />;
                  }}
                />
                {/* <Route exact path={'/jwt/:jwt'} component={JWT} /> */}
                <Route
                  exact
                  path={[
                    "/",
                    "/orders",
                    "/order",
                    "/order/:id",
                    "/users",
                    "/user/:id",
                    "/printers",
                    "/printer/:id",
                  ]}
                  render={(props) => {
                    props.location.qs = qs.parse(
                      props.location.search.slice(1)
                    );
                    return (
                      <Home
                        userId={user.userId}
                        socket={socket}
                        printerName={printerName}
                        zebraPrinter={zebraPrinter}
                        ws={wsClient}
                        {...props}
                      />
                    );
                  }}
                />
              </div>
            )}
          </ConfirmProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}

export default App;

// eslint-disable-next-line
String.prototype.toCapitalCase = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};
// eslint-disable-next-line
String.prototype.toTitleCase = function () {
  return this.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
