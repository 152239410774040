/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../../components/title";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../../components/common/TablePaginationActions";

export default function UserOrders(props) {
  // const classes = useStyles();
  const { userId } = props;
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  useEffect(() => {
    getOrders();
    //eslint-disable-next-line
  }, [page, rowsPerPage]);

  const getOrders = () => {
    let url = `/api/sales/?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&getUser=${userId}&status=all`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        //open dialog
        // console.log(resp);
        setCount(resp.count);
        setOrders(resp.orders);
      })
      .catch((e) => {});
  };
  return (
    <React.Fragment>
      <Title>User Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Order#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right">Sale Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow
              key={row.id}
              style={{
                backgroundColor:
                  row.status === "shipped" ? "lightgrey" : "none",
              }}
            >
              <TableCell>
                <Link to={`/order/${row.id}`}>{row.id}</Link>
              </TableCell>
              <TableCell>
                {row.shipping.name ||
                  row.shipping.firstName + " " + row.shipping.lastName}
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{new Date(row.ts).toLocaleString()}</TableCell>
              <TableCell align="right">
                ${(row.payment.amount / 100).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}
