/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../../components/title";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import TablePaginationActions from "../../components/common/TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function Users() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(Number(e.target.value));
  };

  useEffect(() => {
    let url = `/api/users/?skip=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&all=true`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        //open dialog
        // console.log(resp);
        setCount(resp.count);
        setUsers(resp.users);
      })
      .catch((e) => {});
  }, [page, rowsPerPage]);
  return (
    <React.Fragment>
      <Title>Recent Users</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Avatar</TableCell>
            <TableCell>Name</TableCell>
            {/* <TableCell>Status</TableCell>
            <TableCell>Date</TableCell> */}
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Link to={`/user/${row.userId}`}>
                  <Avatar
                    alt={row.displayName}
                    src={row.avatar}
                    className={classes.large}
                  />
                </Link>
              </TableCell>
              <TableCell>{row.displayName}</TableCell>
              {/* <TableCell>{row.status}</TableCell>
              <TableCell>
                {new Date(row._system.created).toLocaleString()}
              </TableCell> */}
              <TableCell align="right">
                {new Date(row._system.created).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </React.Fragment>
  );
}
