/** @format */

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Route, useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Cookies from "universal-cookie";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExitIcon from "@mui/icons-material/ExitToApp";
import { mainListItems } from "../../components/listitems";
import Orders from "../orders";
import Order from "../order";
import Printers from "../printers";
import Printer from "../printer";
import Dash from "./dash";
import Users from "../users";
import User from "../user";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "black",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText("#3389b8"),
    backgroundColor: "#3389b8",
    fontSize: ".9rem",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const desktopScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { location, printerData, socket, userId, ws } = props;
  const [open, setOpen] = React.useState(desktopScreen);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertLevel, setAlertLevel] = useState("info");
  const [title, setTitle] = React.useState("Dashboard");
  const [openOrders, setOpenOrders] = useState(0);
  const [user, setUser] = useState({ fname: "", avatar: "" });
  let history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const addAlert = (msg, level = "info") => {
    setAlertMsg(msg);
    setAlertLevel(level);
    setAlertOpen(true);
  };

  useEffect(() => {
    if (ws) {
      ws.on("gcodeUpdate", (data) => {
        console.log("data", data);
      });
    }
  }, [ws]);

  useEffect(() => {
    if (desktopScreen !== open) setOpen(desktopScreen);
    // eslint-disable-next-line
  }, [desktopScreen]);

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/orders") {
      getOrders();
    }
    getUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let str = "Dashboard";
    let path = location.pathname.split("/");
    if (path[1]) {
      str = path[1].charAt(0).toUpperCase() + path[1].slice(1);
      if (path[2]) {
        str += " : " + path[2];
      }
    }
    setTitle(str);
  }, [location]);

  const getUser = () => {
    let url = `/api/user/${userId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setUser(resp);
      })
      .catch((e) => {});
  };

  const logout = () => {
    localStorage.clear();
    const cookies = new Cookies();
    cookies.remove("JWT", { path: "/" });
    cookies.remove("USER", { path: "/" });
    window.location.href = "/";
  };

  const gotoProfile = () => {
    history.push("/profile");
  };
  const openDash = () => {
    history.push("/");
  };
  const updateOpenOrders = (count) => {
    setOpenOrders(count);
  };

  const getOrders = () => {
    let url = `/api/sales/?skip=0&limit=1&all=true&status=all`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then((resp) => {
        setOpenOrders(resp.openOrders || 0);
      })
      .catch((e) => {});
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertLevel}>
          {alertMsg}
        </Alert>
      </Snackbar>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          <IconButton color="inherit" onClick={openDash} size="large">
            <Badge badgeContent={openOrders} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={gotoProfile} size="large">
            <Avatar
              alt="Remy Sharp"
              src={user.avatar}
              className={classes.small}
            >
              {user.fname.slice(0, 1)}
            </Avatar>
          </IconButton>
          <IconButton
            color="inherit"
            alt="logout"
            onClick={logout}
            size="large"
          >
            <ExitIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List style={{ height: "100%" }}>{mainListItems}</List>
        {/* <Divider />
        <List>{secondaryListItems}</List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Route exact path="/">
            <Dash
              socket={socket}
              addAlert={addAlert}
              updateOpenOrders={updateOpenOrders}
              {...props}
            />
          </Route>
          <Route exact path="/orders">
            <Orders
              socket={socket}
              updateOpenOrders={updateOpenOrders}
              addAlert={addAlert}
              {...props}
            />
          </Route>
          <Route path="/order/:id">
            <Order
              printerData={printerData}
              socket={socket}
              addAlert={addAlert}
              {...props}
            />
          </Route>
          <Route path="/users">
            <Users addAlert={addAlert} {...props} />
          </Route>
          <Route path="/user/:id">
            <User addAlert={addAlert} {...props} />
          </Route>
          <Route path="/printers">
            <Printers addAlert={addAlert} {...props} />
          </Route>
          <Route path="/printer/:id">
            <Printer addAlert={addAlert} {...props} />
          </Route>
          {/*
          <Route path="/item/:id">
            <Item addAlert={addAlert} {...props} />
          </Route> 
          <Route path="/production">
            <Production addAlert={addAlert} {...props} />
          </Route>
          <Route path="/hollidays">
            <Hollidays addAlert={addAlert} {...props} />
          </Route>
          <Route path="/shippings">
            <Shippings
              printerData={printerData}
              addAlert={addAlert}
              {...props}
            />
          </Route>
          <Route path="/inventory">
            <Inventory addAlert={addAlert} {...props} />
          </Route>
          <Route path="/coupons">
            <Coupons addAlert={addAlert} {...props} />
          </Route>
          <Route path="/chat">
            <Chat addAlert={addAlert} {...props} />
          </Route>
          <Route path="/blog">
            <Blog addAlert={addAlert} {...props} />
          </Route>
          <Route path="/shipping">
            <Shipping {...props} />
          </Route>
          <Route path="/schedule">
            <Schedule {...props} />
          </Route>
          <Route path="/utils">
            <Utils {...props} />
          </Route>
          <Route path="/reporting">
            <Reporting {...props} />
          </Route>
          <Route path="/profile">
            <Profile user={user} {...props} />
          </Route>
          <Route exact path="/users/:id" render={(props)=><Users {...props}/>} /> */}
        </Container>
      </main>
    </div>
  );
}
