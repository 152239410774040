/** @format */

import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme(
  adaptV4Theme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        light: "#63ccff",
        main: "#009be5",
        dark: "#006db3",
        contrastText: "#fff",
      },
    },
    shape: {
      borderRadius: 8,
    },
    overrides: {},
  })
);

export default theme;
