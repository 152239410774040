/** @format */

import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import UserOrders from "./orders";
import UserPrinters from "./printers";
import UserMaterials from "./materials";
import UserFiles from "./files";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
  },
  preview: {
    maxHeight: 75,
  },
  label: {
    position: "absolute",
    bottom: 20,
    right: 20,
    zIndex: 1000,
    opacity: 0,
    visibility: "hidden",
  },
  textField: {
    marginTop: 0,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Orders(props) {
  const { match } = props;
  const id = match.params.id;
  const [user, setUser] = useState({});
  const [fetching, setFetching] = useState(true);
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  useEffect(() => {
    try {
      const getUser = async () => {
        const url = "/api/user/" + id;
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const userData = await res.json();
        setUser(userData);
        setFetching(false);
      };

      getUser();
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  return (
    <>
      {fetching === true ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={5} lg={4}>
            <Paper className={fixedHeightPaper}>
              <div style={{ padding: 40, textAlign: "left" }}>
                <Avatar
                  alt={user.displayName}
                  src={user.avatar}
                  className={classes.large}
                />
                <br />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Name"
                  className={classes.textField}
                  value={user.displayName || user.fname + " " + user.lname}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Registered"
                  className={classes.textField}
                  value={new Date(user.ts).toLocaleString()}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={false} md={false} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4}></Grid>
          <Grid item xs={12}>
            <UserOrders userId={id} />
          </Grid>
          <Grid item xs={12}>
            <UserPrinters userId={id} />
          </Grid>
          <Grid item xs={12}>
            <UserMaterials userId={id} />
          </Grid>
          <Grid item xs={12}>
            <UserFiles userId={id} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
