/** @format */

import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  button: {
    // margin: theme.spacing(1),
  },
  preview: {
    maxHeight: 75,
  },
  label: {
    position: "absolute",
    bottom: 20,
    right: 20,
    zIndex: 1000,
    opacity: 0,
    visibility: "hidden",
  },
  textField: {
    marginTop: 0,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export default function Printer(props) {
  const { match } = props;
  const id = match.params.id;
  const [printer, setPrinter] = useState({});
  const [fetching, setFetching] = useState(true);
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  useEffect(() => {
    try {
      async function getPrinter() {
        let url = "/api/customerprinter/" + id;
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        const printerData = await res.json();
        setPrinter(printerData);
        setFetching(false);
      }

      getPrinter();
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  return (
    <>
      {fetching === true ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={5} lg={4}>
            <Paper className={fixedHeightPaper}>
              <div style={{ padding: 40, textAlign: "left" }}>
                <TextField
                  variant="standard"
                  id="standard-name"
                  label="Name"
                  className={classes.textField}
                  value={printer.name}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-model"
                  label="Model"
                  className={classes.textField}
                  value={printer.model}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="standard"
                  id="standard-user"
                  label="User"
                  className={classes.textField}
                  value={printer.userId}
                  fullWidth
                  margin="normal"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={false} md={false} lg={4}></Grid>
          <Grid item xs={12} md={6} lg={4}></Grid>
        </Grid>
      )}
    </>
  );
}
