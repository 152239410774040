/** @format */

function isValidEmail(text) {
  const emailRegEx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegEx.test(text);
}

function isValidPassword(text) {
  const numberTest = /\d/;
  const capitalTest = /[A-Z]/;
  const specialCharTest = /\W/;
  const excludedCharTest = /\S/;
  const lengthTest = /.{8,}/;
  return (
    numberTest.test(text) &&
    capitalTest.test(text) &&
    specialCharTest.test(text) &&
    excludedCharTest.test(text) &&
    lengthTest.test(text)
  );
}

function isValidEmailEvent(event) {
  const emailRegEx =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegEx.test(event.target.value);
}

function isValidPasswordEvent(event) {
  const numberTest = /\d/;
  const capitalTest = /[A-Z]/;
  const specialCharTest = /\W/;
  const excludedCharTest = /\S/;
  const lengthTest = /.{8,}/;
  return (
    numberTest.test(event.target.value) &&
    capitalTest.test(event.target.value) &&
    specialCharTest.test(event.target.value) &&
    excludedCharTest.test(event.target.value) &&
    lengthTest.test(event.target.value)
  );
}

export {
  isValidEmail,
  isValidPassword,
  isValidEmailEvent,
  isValidPasswordEvent,
};
