/** @format */

import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  items: {
    minWidth: 300,
  },
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
  textfield: {
    margin: 0,
    maxWidth: 100,
  },
}));

export default function AddOrder({ rows, onChange }) {
  const classes = useStyles();
  const [items, setItems] = useState(rows || [{ l: 0, w: 0, h: 0, weight: 1 }]);

  const itemHandler = (index) => (e) => {
    let value = e.target.value;
    let name = e.target.name;
    let tmp = [...items];
    value = isNaN(Number(value)) ? value : Number(value);
    tmp[index][name] = value;
    setItems(tmp);
  };

  const focusHandler = (index) => (e) => {
    let name = e.target.name;
    let tmp = [...items];
    if (tmp[index][name] === 0) {
      tmp[index][name] = "";
      setItems(tmp);
    }
  };

  useEffect(() => {
    onChange(items);
    // eslint-disable-next-line
  }, [items]);

  return (
    <form className={classes.form} noValidate>
      {items.map((item, index) => {
        return (
          <Grid
            key={index}
            container
            className={classes.root}
            alignItems="center"
            justifyContent="space-evenly"
            spacing={2}
          >
            <Grid item>
              <FormControl
                variant="standard"
                margin="dense"
                className={classes.formControl}
              >
                <Select
                  labelId="type-select"
                  id="type-select"
                  value={item.type}
                  variant="outlined"
                  name="type"
                  style={{ minWidth: 100 }}
                  onChange={itemHandler(index)}
                >
                  <MenuItem value={"box"}>Box</MenuItem>
                  <MenuItem value={"bind"}>Bind</MenuItem>
                  <MenuItem value={"pak"}>Pak</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  variant="outlined"
                  name="l"
                  value={item.l}
                  label="Length"
                  id="length"
                  type="number"
                  size="small"
                  disabled={item.type === "pak"}
                  onFocus={focusHandler(index)}
                  className={classes.textfield}
                  onChange={itemHandler(index)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  variant="outlined"
                  name="w"
                  value={item.w}
                  disabled={item.type === "pak"}
                  label="Width"
                  id="width"
                  type="number"
                  size="small"
                  onFocus={focusHandler(index)}
                  className={classes.textfield}
                  onChange={itemHandler(index)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  variant="outlined"
                  name="h"
                  value={item.h}
                  disabled={item.type === "pak"}
                  label="Height"
                  id="height"
                  type="number"
                  size="small"
                  onFocus={focusHandler(index)}
                  className={classes.textfield}
                  onChange={itemHandler(index)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  variant="outlined"
                  name="weight"
                  value={item.weight}
                  label="Weight"
                  id="weight"
                  size="small"
                  type="number"
                  onFocus={focusHandler(index)}
                  className={classes.textfield}
                  onChange={itemHandler(index)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  let tmp = [...items];
                  tmp.splice(index, 1);
                  setItems(tmp);
                }}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <br />
      <Grid item sm={12} style={{ display: "flex" }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            let tmp = [...items];
            tmp.push({ l: 0, w: 0, h: 0, weight: 1 });
            setItems(tmp);
          }}
          endIcon={<AddIcon />}
        >
          Add
        </Button>
      </Grid>
    </form>
  );
}
